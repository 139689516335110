html {
  --color-primary_light: #EDF4F3;;
  --color-primary: #82B1B0;;
  --color-primary_dark: #2B4B51;;
  --color-yellow: #FEDA50;;
  --color-yellow_light: #FBEFC6;;
  --color-yellow_darken: #F4CC54;;
  --color-primary_grey: #95A5A8;;
  --color-darkyellow: #d0b345;;
  --color-secondary_light: var(--color-yellow_light;);
  --color-secondary: var(--color-yellow;);
  --color-blue: #405B73;;
  --color-tertiary: var(--color-blue;);
  --color-red: #D45D3A;;
  --color-green: #5BA54F;;
  --color-danger: var(--color-red;);
  --color-warning: var(--color-red;);
  --color-success: var(--color-green;);
  --color-text_light: #ffffff;;
  --color-modal_bg: #edf4f3f2;;
  --color-light_grey: #8C9FA2;;
}