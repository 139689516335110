.fullpagemodal{
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: top .4s ease;
  max-height: 100vh;
  &.show {
    top: 0;
  }
}