@import '_generated';
@import './widgets/CourseListGrid.scss';
@import './components/CTACard.scss';
@import './components/Onboarding.scss';

html,
body {
    overscroll-behavior-y: contain;

    // The Zoom SDK decides to set this to some nonsense value, lets override:
    min-width: 0 !important;

    height: 100dvh;
    // height: -moz-available;
    // max-height: 100vh;
    // max-height: -webkit-fill-available;
    // max-height: -moz-available;

    * {
        font-family: 'Outfit Variable', sans-serif !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        /* Hide scrollbar on small devices, these are likely mobiles where a scrollbar is not useful */
        @media screen and (max-width: 700px) {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none;
            ::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

html {
    font-size: 16px;
}

#root {
    display: flex;
    flex-wrap: nowrap;
    height: 100dvh;
    // height: -webkit-fill-available;
    // height: -moz-available;
}

div[role='button'] {
    transition: all 0.3s ease;
}

// Tags

.profil-tab-link {
    [role='link'] {
        cursor: default;
        pointer-events: none;
    }
}
