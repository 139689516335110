.onboarding {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }


    &__item {
        flex-basis: calc(50% - 1rem);

        @media screen and (max-width: 900px){ 
            flex-basis: 100%;
        }
    }
}